import React, { useRef,useEffect } from 'react';
import './App.css';
import ChatInterface from './components/ChatInterface';
import AeremSolarGenieFullIcon from './images/aerem_solar_genie_logo.png'



function App() {
  const logoContainerRef = useRef<HTMLDivElement>(null);
  console.log("App loaded")

  useEffect(() => {
    document.title = "Solar Genie"; // Set the tab title
  }, []);

  return (
    <div className="flex flex-col sm:flex-row h-screen sm:w-screen w-full items-center sm:items-start">
      <div className='sm:w-1/5 w-full flex justify-start sm:block sm:h-full h-fit'
      ref={logoContainerRef}>
          {/* <h1 className="text-4xl font-semibold text-orange-400 mt-3 ml-8">Solar Genie</h1> */}
          <img className='sm:h-30 h-fit sm:w-64 w-32 ml-5' src={AeremSolarGenieFullIcon}/>
      </div>
      <div className='sm:w-3/5 w-full px-6 sm:px-0 h-screen'>
        <ChatInterface logoContainerRef={logoContainerRef}/>
      </div>
    </div>
  );
}

export default App;

